import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

const stagingConfig = {
  apiKey: "AIzaSyBasCXNWwp57V-NknfhrCJIeG-bszTmyr4",
  authDomain: "retrominter-staging.firebaseapp.com",
  projectId: "retrominter-staging",
  storageBucket: "retrominter-staging.appspot.com",
  messagingSenderId: "639009876106",
  appId: "1:639009876106:web:bd7e2b461b4ae5c641e573",
};

const productionConfig = {
  apiKey: "AIzaSyDM5Qs6EXaGx-y4SIM0ZpHI9aAKFwbnBNA",
  authDomain: "minting.retronfts.art",
  projectId: "retrominter",
  storageBucket: "retrominter.appspot.com",
  messagingSenderId: "424847566143",
  appId: "1:424847566143:web:cae768a4b6acc8c1cf1f8a",
  measurementId: "G-LTPBLGCKEF",
};

const prod = process.env.NODE_ENV === "production";

const firebaseConfig = prod ? productionConfig : stagingConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const recaptchaKey = prod
  ? "6Lc2MnAdAAAAANTmg0F5NxEBxpsTNSyE1iZqchnM"
  : "6LdFDkQdAAAAAL7em5nV0cPQkY8BxWYQcY9tDfWH";

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(recaptchaKey),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

const functions = getFunctions(app, "us-east4");

export const db = getFirestore();
export const analytics = getAnalytics();
export const enterQueue = httpsCallable(functions, "enterQueue");
export const checkOnWhitelist = httpsCallable(functions, "checkOnWhitelist");

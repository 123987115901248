<template>
  <div>
    <footer class="d-flex flex-column justify-content-center px-3">
      <b-row>
        <b-col
          id="img-text-f-container"
          sm="12"
          md="6"
          class="d-flex align-items-center"
        >
          <a href="https://retronfts.art" target="_blank">
            <img
              alt="RetroNFTs logo"
              src="../assets/RetroNFTs-logo.png"
              class="footer-img"
            />
          </a>
          <span class="text-light ms-2" style="height: 10px;">By Blockchain Pilots</span>
        </b-col>
        <b-col id="link-f-container" sm="12" md="6" class="text-right">
          <a
            href="https://pool.pm/383ee9f587c77c5683eea207359749784d9af5cd2306d099b9397f3f"
            class="stake-pool-link text-decoration-none"
            target="_blank"
            >Stake with RetroNFTs pool</a
          >
        </b-col>
      </b-row>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
footer {
  position: fixed;
  bottom: 0;
  height: 60px;
  background-color: #000;
  width: 100%;
}

.footer-img {
  max-width: 140px;
}

.text-right {
  text-align: right;
}

.footer-img + span {
  font-size: 14px;
}

@media (max-width: 768px) {
  #img-text-f-container,
  #link-f-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 376px) {
  .stake-pool-link {
    display: none;
  }

  #img-text-f-container {
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 5px;
  }

  #img-text-f-container span {
    margin-left: 0 !important;
  }
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "ConsoleLogin",
    component: () =>
      import(
        /* webpackChunkName: "console-login" */ "../views/console/Login.vue"
      ),
  },
  {
    path: "/console",
    component: () =>
      import(/* webpackChunkName: "console" */ "../views/console/Wrapper.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "ConsoleHome",
        component: () =>
          import(
            /* webpackChunkName: "console-home" */ "../views/console/Home.vue"
          ),
      },
      {
        path: "reports",
        name: "ConsoleReports",
        component: () =>
          import(
            /* webpackChunkName: "console-reports" */ "../views/console/Reports.vue"
          ),
      },
      {
        path: "addcollection",
        name: "AddCollection",
        component: () =>
          import(
            /* webpackChunkName: "console-addcollection" */ "../views/console/AddCollection.vue"
          )
      }, {
        path: "getcollection",
        name: "GetCollection",
        component: () =>
          import(
            /* webpackChunkName: "console-getcollection" */ "../views/console/GetCollection.vue"
          ),
      },
      {
        path: "whitelist/:id",
        name: "ConsoleWhitelist",
        component: () =>
          import(
            /* webpackChunkName: "console-whitelist" */ "../views/console/Whitelist.vue"
          ),
      },
      {
        path: "whitelist",
        name: "ConsoleWhitelistRedirect",
        redirect: "/console",
      },
      {
        path: "error",
        name: "ConsoleError",
        component: () =>
          import(
            /* webpackChunkName: "console-error" */ "../views/console/Error.vue"
          ),
      },
      {
        path: "*",
        name: "ConsoleNotFound",
        redirect: "/console",
      },
    ],
  },
  {
    path: "/:client",
    component: () =>
      import(
        /* webpackChunkName: "client-wrapper" */ "../views/ClientWrapper.vue"
      ),
    children: [
      {
        path: "",
        name: "ClientPage",
        component: () =>
          import(/* webpackChunkName: "client" */ "../components/Client.vue"),
      },
      {
        path: ":collection",
        name: "ClientCheckout",
        component: () =>
          import(
            /* webpackChunkName: "checkout" */ "../components/Checkout.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth = getAuth();
  const user = auth.currentUser;
  let isAuthed = false;

  if (user !== null) {
    try {
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let profile = docSnap.data();
        profile.id = docSnap.id;
        profile.displayName = user.displayName;
        store.commit("setProfileError", false);
        store.commit("setUserProfile", profile);
        isAuthed = true;
      } else {
        store.commit("setProfileError", true);
        store.commit("setUserProfile", null);
        signOut(auth);
      }
    } catch (err) {
      console.log("Error getting user doc");
    }
  } else {
    store.commit("setUserProfile", null);
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (to.path === "/login" && isAuthed) {
    next("/console");
  }

  if (requiresAuth && !isAuthed) {
    next("/login");
  }

  if (from.name === "ClientPage") {
    to.meta.noCollection = true;
  }
  next();
});
export default router;

<template>
  <div id="app">
    <b-navbar id="nav" type="dark">
      <b-container>
        <b-navbar-brand href="/" class="py-0">
        <div id="logo">
        </div>
          <!-- <img alt="RetroNFTs logo" src="./assets/logo.png" /> -->
        </b-navbar-brand>
        <b-navbar-nav v-if="isAuthed" class="ml-auto">
          <b-nav-item-dropdown :text="userName" right>
            <b-dropdown-item to="/console">Console</b-dropdown-item>
            <b-dropdown-item v-if="isAdmin" to="/console/reports"
              >Reports</b-dropdown-item
            >
            <b-dropdown-item v-if="isAdmin" to="/console/getcollection"
              >Get Collection</b-dropdown-item
            >
            <b-dropdown-item v-if="isAdmin" to="/console/addcollection"
              >Add Collection</b-dropdown-item
            >
            <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { analytics } from "@/firebase";
import { logEvent } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
  },
  metaInfo() {
    return {
    changed(metaInfo) {
      logEvent(analytics, "screen_view", {
        firebase_screen: metaInfo.title,
      });
    },
    title: "RetroNFTs Cardano NFT Minting",
    meta: [
      {name: "description", content: "We have been minting NFTs on the Cardano blockchain since April 2021"},
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:url",
        content: "https://minting.retronfts.art",
      },
      {
        vmid: 'og:title',
        property: "og:title",
        content: "RetroNFTs Cardano NFT Minting",
      },
      {
        property: "og:description",
        content:
          "We have been minting NFTs on the Cardano blockchain since April 2021",
      },
      {
        property: "og:image",
        content:
          "https://retronfts.art/assets/img/logos/RetroNFT-logo-banner-og.jpg",
      },
    ],
  }
  },
  data: () => ({}),
  computed: {
    isAuthed() {
      return this.$store.getters.isAuthed;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    userName() {
      return this.$store.getters.userProfile.displayName
        ? this.$store.getters.userProfile.displayName.split(" ")[0]
        : "User";
    },
  },
  methods: {
    logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.push({ name: "ConsoleLogin" });
      });
    },
  },
};
</script>

<style>
body {
  margin: 0;
  background: url("./assets/background.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #e5e5f7;
}

#app {
  font-family: lexend, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  padding-bottom: 100px;
}

#logo {
  background: url("./assets/RetroNFTs-logo.png") center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 46px;
  width: 250px;
}

#nav {
  padding: 10px;
  background: rgba(0, 0, 0, 0.9);
}

#nav img {
  max-height: 50px;
}

.navbar-expand .navbar-nav .dropdown-menu {
    right: 5px;
}

@media screen and (max-width: 767px) {
  #logo {
    width: 180px;
  }
}
</style>

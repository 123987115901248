import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userProfile: null,
    profileError: false,
  },
  mutations: {
    setUserProfile(state, payload) {
      state.userProfile = payload;
    },
    setProfileError(state, payload) {
      state.profileError = payload;
    },
  },
  actions: {},
  getters: {
    isAuthed(state) {
      return state.userProfile !== null;
    },
    userProfile(state) {
      return state.userProfile;
    },
    isAdmin(state) {
      return state.userProfile && state.userProfile.role === "admin";
    },
    userClients(state) {
      return state.userProfile && state.userProfile.clients;
    },
    profileError(state) {
      return state.profileError;
    },
  },
  modules: {},
});
